import React from 'react';
import { graphql } from 'gatsby';
import { ButtonsPrimary } from '../../components/atoms/buttons';
import ContextualCat from '../../components/ContextualCta';
import Footer from '../../components/Footer';
import Deindex from '../../components/Deindex';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import PropsPage404 from '../../interface/template';
import withI18next from '../../i18n/TemplateI18n';
import '../style.scss';

const Page404: React.SFC<any> = (props: PropsPage404) => {
  const { t, pageContext } = props;
  const { url, pageName } = pageContext;

  return (
    <>
      <Deindex />
      <Header
        url={url}
        showBackground
        showLanguages={false}
        pageName={pageName}
      >
        <PageTitle
          title={t('not-found:notFound.mainHeadline.title')}
          subtitle={t('not-found:notFound.mainHeadline.subtitle')}
        >
          <ButtonsPrimary className="visible">Go back home</ButtonsPrimary>
        </PageTitle>
      </Header>
      <ContextualCat />
      <Footer page={pageName} />
    </>
  );
};
export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "nl" }
        ns: {
          regex: "/(not-found)|(menu)|(eyebrow)|(breadcrumbs)|(contextual-cta)|(seo)|(banner)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;
export default withI18next('nl')(Page404);
